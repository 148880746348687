<template>
  <v-container fluid>
    <v-card>
      <v-card-title
        :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"
        class="
          card-header card-header-title
          font-size-lg
          text-capitalize
          font-weight-normal
        "
      >
        {{ $t("analytics.lang_salesPerYear") }}
      </v-card-title>

      <v-card-text class="mt-6">
        <v-row>
          <v-col>
            <!--<v-combobox
              v-model="date"
              :items="years"
              :label="$t('generic.lang_date')"
              dense
              outlined
              clearable
              small-chips
            ></v-combobox>-->

            <v-select
              v-model="date"
              :items="years"
              :label="$t('generic.lang_date')"
              class="mx-auto"
              dense
              outlined
            />
            <v-checkbox :label="$t('generic.lang_compareWith')" v-model="isCompare"/>
            <v-select
                v-if="isCompare"
                v-model="comparedDate"
                :items="years"
                :label="$t('generic.lang_date')"
                class="mx-auto"
                dense
                outlined
            />
          </v-col>

          <v-col>
            <v-btn
              :disabled="this.loading || !date"
              :loading="this.loading"
              @click="getSalesData"
              block
              class="bg-primary text-light mx-auto py-5 ma-0"
            >
              <v-icon class="ma-1">remove_red_eye</v-icon>
              {{ this.$t("generic.lang_display") }}
            </v-btn>
          </v-col>

          <v-col cols="12" class="pa-0 ma-0">
            <v-divider class="pa-0 ma-0" />
          </v-col>

          <v-col cols="12" v-if="showTopChart">
            <div class="py-6">
              {{ isCompare?date+ ' => ':'' }}
              {{ this.$t("analytics.lang_annualTurnoverNet") + ": " }}
              {{ turnoverNet | currency }}
              {{ " / " + this.$t("analytics.lang_annualTurnoverGross") + ": " }}
              {{ turnoverGross | currency }}
            </div>
            <div v-if="isCompare" class="py-6">
              {{ comparedDate+ ' => ' }}
              {{ this.$t("analytics.lang_annualTurnoverNet") + ": " }}
              {{ turnoverNetCompared | currency }}
              {{ " / " + this.$t("analytics.lang_annualTurnoverGross") + ": " }}
              {{ turnoverGrossCompared  | currency }}
            </div>
          </v-col>

          <v-col cols="12" class="mx-auto">
            <v-card v-if="showTopChart">
              <v-card-text>
                <apexchart
                  type="line"
                  height="700"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>

                <div v-if="loading" class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import { Events } from "@/plugins/events";

var self=null;
export default {
  name: "SalesPerYearStatisticsComponent",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      ENDPOINTS,
      date: null,
      years: [],
      comparedDate: null,
      loading: false,
      isCompare: false,
      showTopChart: false,
      turnoverGross: 0,
      turnoverNet: 0,
      turnoverGrossCompared: 0,
      turnoverNetCompared: 0,
      series: null,
      chartOptions: {
        theme: {
          mode: this.$vuetify.theme.dark? 'dark' : 'light', 
        },
        stroke: {
          curve: "smooth",
          width: [0, 4],
        },
        labels: [],
        xaxis: {
          title: {
            text: this.$t("generic.lang_month"),
          },
        },
        yaxis: [
          {
            title: {
              text: this.$t("analytics.lang_turnoverGross"),
            },
            labels: {
              formatter: function (val) {
                return self.$options.filters.currency(val);
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    self = this;
    var currentYear = new Date().getFullYear();
    var startYear = 2000;
    for (var i = startYear; i <= currentYear; i++) {
      this.years.push(startYear++);
    }
    this.years.sort().reverse();
    this.years.sort((a, b) => b.distance - a.distance);
  },
  methods: {
    getFormattedDate(date){
      return date === "" || date == null? null : moment(date, "YYYY").format("DD.MM.YYYY");
    },
    async getSalesData() {
      this.loading = true;
      this.showTopChart = false;
      this.turnoverGross = 0;
      this.turnoverNet = 0;
      this.series = [
        {
          name: this.$t("analytics.lang_turnoverGross"),
          type: "column",
          data: [],
        },
        {
          name: this.$t("analytics.lang_turnoverNet"),
          type: "line",
          data: [],
        },
      ];

      if(this.isCompare){
        this.series.push(
            {
              name: this.$t("analytics.lang_turnoverGross"),
              type: "column",
              data: [],
            },
            {
              name: this.$t("analytics.lang_turnoverNet"),
              type: "line",
              data: [],
            }
        )
      }

      this.chartOptions.labels = [];

      const params = {
        date:this.getFormattedDate(this.date),
      };

      var config = {
        method: "post",
        url: `${ENDPOINTS.DATATABLES.ANALYTICS.UMSATZANALYSEN.SALESPERYEAR}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(params),
      };

      await this.axios(config)
        .then((res) => {
          if (res.status === 200) {
            if (
              (Array.isArray(res.data) && !res.data.length) ||
              res.data == null
            ) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_noDataToDisplay"),
                color: "warning",
              });
              return;
            }

            this.series[0].name += ' ' + this.date
            this.series[1].name += ' ' + this.date

            for (let i = 0; i < res.data.length; i++) {
              this.turnoverGross += res.data[i]["total"];
              this.turnoverNet += res.data[i]["totalNet"];

              this.chartOptions.labels.push(res.data[i]["month"].substring(0,2));
              this.series[0]["data"].push(res.data[i]["total"]);
              this.series[1]["data"].push(res.data[i]["totalNet"]);
            }

            this.showTopChart = !this.isCompare;

            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_dataLoading"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });

      if(this.isCompare){
        params.date = this.getFormattedDate(this.comparedDate);
        config.data = JSON.stringify(params);
        await this.axios(config)
            .then((res) => {
              if (res.status === 200) {
                if (
                    (Array.isArray(res.data) && !res.data.length) ||
                    res.data == null
                ) {
                  Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_noDataToDisplay"),
                    color: "warning",
                  });
                  return;
                }

                this.series[2].name+=' '+this.comparedDate
                this.series[3].name+=' '+this.comparedDate

                for (let i = 0; i < res.data.length; i++) {
                  this.turnoverGrossCompared += res.data[i]["total"];
                  this.turnoverNetCompared += res.data[i]["totalNet"];

                  this.chartOptions.labels.push(res.data[i]["month"].substring(0,2));
                  this.series[2]["data"].push(res.data[i]["total"]);
                  this.series[3]["data"].push(res.data[i]["totalNet"]);
                }

                this.showTopChart = true;

                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_dataLoading"),
                  color: "success",
                });
              } else {
                Events.$emit("showSnackbar", {
                  message: res.data.msg || this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              }
            })
            .catch((err) => {
              Events.$emit("showSnackbar", {
                message: err.message,
                color: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
  },
};
</script>
